<script>
import api from "@/helpers/api-rest/api.js";
import format from 'date-fns/format';
import ResumeInvoiceModal from "./resume-invoice-modal.vue";
import Vue from "vue";

const initialState = () => {
    return {
        isModalOpen: false,
        dataUser: [],
        trackingStatusList: [],
        selectOptionsActions: [],
        collapseOpen: [],
        resumeInvoiceModalId: 'ModalResumeInvoice',
        resumeEdit: undefined,
        loading: false,
        periodic_tracking:{
          id:null,          
        },
        periodic_tracking_rows: [],           
    };
}

export default {
  emits: ['onClose'],
  props: {
    id: { type: String, default: "ModalPeriodicTracking" },
    periodicTrackingId: Number,
    essayId: { type: [Number, String] },
    tracking: {
      type: Object,
      default: () => {
        return {
          id: null,
          tracking_control_economic: []
        };
      },
    },
  },
  components: { ResumeInvoiceModal },
  data() {
      return initialState();
  },
  computed: {
    modalTitle() {
        return this.tracking?.id ? "Editar seguimiento" : "Añadir seguimiento";
    },
    submitButtonText() {
      return this.tracking?.id ? 'Guardar' : 'Añadir';
    },
  },
  methods: {
    loadSelectOptionsTrackingStatus(){
        const apiEndpoint = `/api/v1/tracking-status`;
        api.get({url:apiEndpoint})
        .then(response => {
            this.trackingStatusList = response?.data?.data;
        })
        .catch(error => {
            console.error("Error al cargar los estados:", error);
        });
    },
    async loadActionsForEssay(){

      try {
        const response = await api.get({
            url: `/api/v1/essay-actions?essay_id=${this.essayId}`,
            config: {
                withLoading: true
            }  
        });
        return response?.data?.data;
      } catch (error) {
        console.error("Error al cargar las acciones:", error);
      }
      
    },
    async loadPeriodicTracking(){

      try {
        const response = await api.get({
          url: `/api/v1/periodic-trackings/${this.periodicTrackingId}`,
          config: {
              withLoading: true
          }  
        });
        return response?.data?.data;

      } catch (error) {
        console.error("Error al cargar el seguimiento:", error);
      }
      
    },
    addRowControlEconomic(periodicTrackingRow){
      periodicTrackingRow.tracking_control_economics.push({
        id: null,
        material: '',
        cost: '',
        document_path: '',
        document_name: 'Selecciona un archivo...',
      });
    },
    deleteRowControlEconomic(periodicTrackingRow, key){
      periodicTrackingRow.tracking_control_economics.splice(key, 1);
    },
    onFileChange(event, periodicTrackingRow, type) {
      const file = event.target.files[0];
      this.uploadFile(file, 'trackings')
      .then((response) => {
        const responseFile = response?.data?.data;
        switch (type) {
          case 'graphic':
            periodicTrackingRow.document_path_graphic = responseFile.path;
            periodicTrackingRow.document_name_graphic = file.name;
            break;
          case 'technical':
            periodicTrackingRow.document_path_technical = responseFile.path;
            periodicTrackingRow.document_name_technical = file.name;
            break;
          case 'formative':
            periodicTrackingRow.document_path_formative = responseFile.path;
            periodicTrackingRow.document_name_formative = file.name;
            break;
        }
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    onFileChangeControl(event, trackingControlEconomic){
      const file = event.target.files[0];
      this.uploadFile(this.file, 'invoices')
      .then((response) => {
        const responseFile = response?.data?.data;
        trackingControlEconomic.document_name = file.name;
        trackingControlEconomic.document_path = responseFile.path;
      })
      .catch((error) => {
        console.error("Error al cargar el archivo:", error);
      });
    },
    uploadFile(file, folder){
      const formData = new FormData();
      formData.set("file", file);
      formData.append("path", folder);
      const config = { headers: { "content-type": "multipart/form-data" } };

      const apiEndpoint = `/api/v1/upload-file`;
      return api.post({ url: apiEndpoint, data: formData, config })
    },
    toggleCollapse(index) {
      console.log(this.collapseOpen);
      this.$set(this.collapseOpen, index, !this.collapseOpen[index]);
      console.log(this.collapseOpen);
    },
    submitForm() {
        // if(this.center?.id){
        //     return this.updateCenter();
        // }
        // return this.createCenter();
    },
    cancelForm() {
        this.$bvModal.hide(this.id);
    },
    editTracking(resume) {
      this.resumeEdit = resume;
      this.$bvModal.show(this.resumeInvoiceModalId);
    },
    addResumeInvoice(){
      console.log("Crear control económico");
      this.$bvModal.show(this.resumeInvoiceModalId);
    },
    async loadData(){

      this.loadSelectOptionsTrackingStatus();

      let periodic_tracking = null;
      if (this.periodicTrackingId) {
          periodic_tracking = await this.loadPeriodicTracking();
          this.periodic_tracking = periodic_tracking;
      }
      
      const actions = await this.loadActionsForEssay();
      this.wrapPeriodicTrackingRowsWithActions(periodic_tracking?.periodic_tracking_rows, actions);

    },
    wrapPeriodicTrackingRowsWithActions(periodic_tracking_rows, actions){
      actions.forEach(action => {
        action.init_date = format(new Date(action.init_date), 'dd/MM/yyyy');
        action.end_date = format(new Date(action.end_date), 'dd/MM/yyyy');
        let periodic_tracking_row = periodic_tracking_rows?.find(row => row?.essay_action_id === action.id);

        if (!periodic_tracking_row) {
          periodic_tracking_row = {
            id: null,
            essay_action_id:null,
            periodic_tracking_id:null,
            tracking_status_id:3,
            created_by_id:null,
            updated_by_id:null,
            contingency:null,
            observation:null,
            document_path_graphic:null,
            document_name_graphic:null,
            document_path_technical:null,
            document_name_technical:null,
            document_path_formative:null,
            document_name_formative:null,
            tracking_control_economics:[]
          }
        }

        periodic_tracking_row.essay_action_id = action.id;
        periodic_tracking_row.essay_action = action;

        this.periodic_tracking_rows.push(periodic_tracking_row);

      });
    },
    onClose(){
      this.resetModal();
      this.$emit("onClose");
    },
    resetModal() {
      Object.assign(this.$data, initialState());
    },    
    async savePeriodicTracking(){
      const response = await api.post({
          url: `/api/v1/periodic-trackings`,
          data:{
            essay_id: this.essayId
          }         
      });
      this.periodic_tracking = response?.data?.data;
    },
    changeStatus(periodic_tracking_row, status_id){
      periodic_tracking_row.tracking_status_id = status_id;
      if(periodic_tracking_row.id){
        this.savePeriodicTrackingRow(periodic_tracking_row);
      }
    },
    async savePeriodicTrackingRow(periodicTrackingRow){

      try{
        if(!this.periodic_tracking?.id){
          await this.savePeriodicTracking();
        }
        const response = await api.save({
            url: `/api/v1/periodic-tracking/${this.periodic_tracking?.id}/periodic-tracking-rows`,
            id: periodicTrackingRow?.id,
            data: periodicTrackingRow             
        });

        Object.assign(periodicTrackingRow, response?.data?.data);

        Vue.swal({
            position: "top-end",
            icon: "success",
            title: "Seguimiento guardado exitosamente",
            showConfirmButton: false,
            timer: 2000
        });

      } catch (error) {
        console.error("Error al guardar el seguimiento:", error);
      }
      
      
    },
  },
};
</script>
<template>
  <div>
    <ResumeInvoiceModal :id="resumeInvoiceModalId" :resume="resumeEdit"/>
    <b-modal :id="id" :title="modalTitle" @shown="loadData" @hidden="onClose" scrollable :size="'extra-xl'">
      <template #default="{ }">
        <form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Acciones</h4>
              <b-card no-body class="mb-1 shadow-lg mt-4" v-for="(periodic_tracking_row, i) in periodic_tracking_rows" :key="i">
                <b-card-header header-tag="header" class="p-3 sticky-header" role="tab">
                  <h6 class="m-0 d-flex justify-content-between align-items-center">
                    <div class="form-inline">
                      <span>{{ periodic_tracking_row?.essay_action?.name }} <small class="font-italic ml-3">({{ periodic_tracking_row?.essay_action?.init_date }} - {{ periodic_tracking_row?.essay_action?.end_date }})</small></span>                      
                    </div>
                    <div class="text-right">
                      <button
                        v-for="(status, j) in trackingStatusList"
                        :key="j"
                        type="button"
                        @click="changeStatus(periodic_tracking_row, status?.id)"
                        class="btn btn-sm mr-1"
                        :class="{
                          'btn-light': periodic_tracking_row?.tracking_status_id != status?.id,
                          ['btn-' + status.badge_color]: periodic_tracking_row?.tracking_status_id == status?.id
                        }"
                      >{{status?.description}}</button>
                      <!-- <b-button size="sm" pill variant="info" class="mr-4">Justificante</b-button> -->
                      <b-icon style="margin-right: 10px; cursor: pointer" :icon="collapseOpen['collapse-' + i] ? 'chevron-down' : 'chevron-up'" @click="toggleCollapse('collapse-' + i)"  v-b-toggle="'collapse-' + i"></b-icon>
                    </div>
                  </h6>
                </b-card-header>
                <b-collapse :id="'collapse-' + i" accordion="variation-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-lg-3">
                            <h6>Nombre</h6>
                            <p class="text-muted">
                                {{ periodic_tracking_row?.essay_action?.name }}
                            </p>
                        </div>
                        <div class="col-lg-3">
                          <h6>Responsable</h6>
                          <p class="text-muted">
                              {{ periodic_tracking_row?.essay_action?.responsables }}
                          </p>
                        </div>
                        <div class="col-lg-3">
                          <h6>Fecha inicial</h6>
                          <p class="text-muted">
                              {{ periodic_tracking_row?.essay_action?.init_date }}
                          </p>
                        </div>
                        <div class="col-lg-3">
                          <h6>Fecha final</h6>
                          <p class="text-muted">
                              {{ periodic_tracking_row?.essay_action?.end_date }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-12 mt-4">
                          <h6>Descripción</h6>
                          <p class="text-muted">
                              {{ periodic_tracking_row?.essay_action?.descriptions }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-12 mt-4">
                          <h6>Plan de contingencia</h6>
                          <p class="text-muted">
                              {{ periodic_tracking_row?.essay_action?.contingency_plan }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-lg-12 mt-4">
                          <h6>Riesgos</h6>
                          <p class="text-muted">
                              {{ periodic_tracking_row?.essay_action?.risks }}
                          </p>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-lg-12">
                          <div class="form-group">
                            <label for="observationTarget">Observaciones</label>
                            <textarea v-model="periodic_tracking_row.observation" class="form-control" id="observationTarget" rows="3" placeholder="Observaciones"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label for="file">Documento gráfico</label>
                            <div class="custom-file">
                              <input id="inputFileGrafico" type="file" name="file" class="custom-file-input" @change="event => onFileChange(event, periodic_tracking_row, 'graphic')" />
                              <label class="custom-file-label" for="inputFileGrafico">{{ periodic_tracking_row?.document_name_graphic || 'Seleccione un archivo' }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label for="file">Documento técnico</label>
                            <div class="custom-file">
                              <input id="inputFileTecnico" type="file" name="file" class="custom-file-input" @change="event => onFileChange(event, periodic_tracking_row, 'technical')" />
                              <label class="custom-file-label" for="inputFileTecnico">{{ periodic_tracking_row?.document_name_technical || 'Seleccione un archivo' }}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="form-group">
                            <label for="file">Documento formativo</label>
                            <div class="custom-file">
                              <input id="inputFileFormativo" type="file" name="file" class="custom-file-input" @change="event => onFileChange(event, periodic_tracking_row, 'formative')" />
                              <label class="custom-file-label" for="inputFileFormativo">{{ periodic_tracking_row?.document_name_formative || 'Seleccione un archivo' }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-lg-12">
                          <label>Control económico</label>
                          <table class="table table-bordered table-sticky-header">
                            <thead>
                              <tr>
                                <th scope="col">Material</th>
                                <th scope="col">Coste (€)</th>
                                <th scope="col">Factura</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(control, i) in periodic_tracking_row?.tracking_control_economics" :key="i" class="">
                                <td>
                                  <div class="form-group">
                                    <input type="text" v-model="control.material" class="form-control" placeholder="Material" />                                          
                                  </div>
                                </td>
                                <td>
                                  <div class="form-group">
                                    <input type="text" v-model="control.cost" class="form-control" placeholder="Coste" />                                     
                                  </div>
                                </td>
                                <td>
                                  <div class="form-group">
                                    <div class="custom-file">
                                      <input id="inputFileInvoice" type="file" name="file" class="custom-file-input" @change="event => onFileChangeControl(event, control)" />
                                      <label class="custom-file-label" for="inputFileInvoice">{{ control?.document_name || 'Seleccione un archivo' }}</label>
                                    </div>                                      
                                  </div>
                                </td>
                                <td class="text-center">
                                    <a
                                        href="javascript:void(0);"
                                        class="text-danger"
                                        v-b-tooltip.hover
                                        title="Eliminar"
                                        @click="deleteRowControlEconomic(periodic_tracking_row,i)"
                                    >
                                        <i class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td colspan="4" class="text-right">
                                    <a href="javascript:void(0);" class="text-success" @click="addRowControlEconomic(periodic_tracking_row)">
                                      <i class="mdi mdi-plus-circle font-size-18"></i> Agregar control económico
                                    </a>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-lg-12">
                          <h6>Justificación económica</h6>
                        </div>
                        <div class="col-lg-12">
                          

                          <b-tabs class="nav-tabs-custom" content-class="border border-top-0 p-4">
                            <!-- Tab información -->
                            <b-tab title="Datos sobre la actuación">
                              <div class="form-group">
                                <label for="actionType">Tipo de actuación</label>
                                <textarea v-model="periodic_tracking_row.observation" class="form-control" id="actionType" rows="3" placeholder="Observaciones"></textarea>
                              </div>
                            </b-tab>
                            <b-tab title="Datos sobre receptor/a">
                                <div class="row">
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label for="receptor-identification">NIF / CIF</label>
                                        <input type="text" id="receptor-identification" v-model="periodic_tracking_row.observation" class="form-control"/>                                     
                                      </div>
                                    </div>
                                    <div class="col-lg-6">
                                      <div class="form-group">
                                        <label for="receptor-name">Nombre o razón social</label>
                                        <input type="text" id="receptor-name" v-model="periodic_tracking_row.observation" class="form-control" />                                     
                                      </div>
                                    </div>
                                </div>
                            </b-tab>
                            <b-tab title="Datos sobre el gasto">
                              <table class="table table-bordered table-sticky-header">
                                <thead>
                                  <tr>
                                    <th scope="col">Descripción</th>
                                    <th scope="col">Nº de factura ó referencia documento probatorio del gasto</th>
                                    <th scope="col">Fecha de factura ó documento probatorio del gasto</th>
                                    <th scope="col">Importe neto en doc. probatorio del gasto</th>
                                    <th scope="col">Importe IVA o similar en doc. probatorio del gasto</th>
                                    <th scope="col">Importe total doc. probatorio del gasto</th>
                                    <th scope="col">Importe neto imputado</th>
                                    <th scope="col">Importe IVA o similar imputado</th>
                                    <th scope="col">Importe total imputado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(control, i) in periodic_tracking_row?.tracking_control_economics" :key="i" class="">
                                    <td>
                                      <strong>{{control?.material}}</strong>
                                    </td>
                                    <td>
                                      <div class="form-group">
                                        <input type="number" v-model="control.cost" class="form-control"/>                                     
                                      </div>
                                    </td>                                    
                                    <td>
                                      <div class="form-group">
                                        <input type="date" v-model="control.cost" class="form-control"/>                                     
                                      </div>
                                    </td>                                    
                                    <td>
                                      <div class="form-group">
                                        <input type="number" v-model="control.cost" class="form-control"/>                                     
                                      </div>
                                    </td>                                    
                                    <td>
                                      <div class="form-group">
                                        <input type="number" v-model="control.cost" class="form-control"/>                                     
                                      </div>
                                    </td>                                    
                                    <td>
                                      <div class="form-group">
                                        <input type="number" v-model="control.cost" class="form-control"/>                                     
                                      </div>
                                    </td>                                    
                                    <td>
                                      <div class="form-group">
                                        <input type="number" v-model="control.cost" class="form-control"/>                                     
                                      </div>
                                    </td>                                    
                                    <td>
                                      <div class="form-group">
                                        <input type="number" v-model="control.cost" class="form-control"/>                                     
                                      </div>
                                    </td>   
                                    <td class="text-right">
                                      <h6>{{control?.cost}}</h6>
                                    </td>                                 
                                  </tr>
                                  <tr v-if="periodic_tracking_row?.tracking_control_economics && periodic_tracking_row?.tracking_control_economics.length == 0">
                                    <td colspan="9" class="text-center text-muted">
                                      Debes añadir al menos un control económico
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </b-tab>

                            <b-tab title="Datos sobre el pago">
                                <div class="row">
                                    <div class="col-lg-4">
                                      <div class="form-group">
                                        <label for="file">Documento probatorio del pago</label>
                                        <div class="custom-file">
                                          <input id="inputFileTecnico" type="file" name="file" class="custom-file-input" @change="event => onFileChange(event, periodic_tracking_row, 'technical')" />
                                          <label class="custom-file-label" for="inputFileTecnico">{{ periodic_tracking_row?.document_name_technical || 'Seleccione un archivo' }}</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-4">
                                      <div class="form-group">
                                        <label for="receptor-name">Fecha del pago</label>
                                        <input type="date" v-model="periodic_tracking_row.cost" class="form-control"/> 
                                      </div>
                                    </div>
                                    <div class="col-lg-4">
                                      <div class="form-group">
                                        <label for="receptor-name">Información adicional</label>
                                        <input type="text" v-model="periodic_tracking_row.cost" class="form-control"/> 
                                      </div>
                                    </div>
                                </div>
                            </b-tab>

                        </b-tabs>









                        </div>
                      </div>
                      <!-- <div class="row mt-4">
                        <div class="col-lg-12 text-center p-4">

                          <a href="javascript:void(0);" class="text-success" @click="addResumeInvoice()">
                            <i class="mdi mdi-plus-circle font-size-18"></i> Añadir justificación económica
                          </a>
                        </div>
                      </div> -->
                    </b-card-text>
                  </b-card-body>
                  <b-card-footer class="text-right">
                    <b-button variant="success" @click="savePeriodicTrackingRow(periodic_tracking_row)" :disabled="loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                      Guardar
                    </b-button>
                  </b-card-footer>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </form>
      </template>
      <template #modal-footer="{  }">
        <b-button @click="cancelForm()">Cerrar</b-button>
      </template>
    </b-modal>
  </div>
</template>